body {
  font-family: 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji',
    sans-serif;
  font-weight: 400;
  margin: 0 auto;
}

.l-header {
  display: flex;

  box-sizing: border-box;
  width: 100%;
  min-height: 5em;
  padding: 1em;

  align-content: space-between;
  align-items: stretch;
  justify-content: space-between;

  h1 {
    box-sizing: border-box;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

.l-article {
  margin: 3em 2em;
}

.l-title {
  flex-grow: 1;
}

img.logo {
  width: 100%;
  min-width: 76px;
  height: auto;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
input.ReactTags__tagInputField::placeholder {
  font-size: 12px;
  padding: 10px 0;
  font-family: 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji',
    sans-serif;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  font-size: 16px;
  width: 100%;
  outline: none;
  padding: padding;
  padding: 5px 10px;
  font-family: 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji',
    sans-serif;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  background: #d1e5f1;
  color: #527893;
  font-size: 12px;
  display: inline-block;
  padding: 5px 6px;
  margin: 0 5px;
  cursor: move;
  border-radius: 3px;
  border-color: transparent;
}
div.ReactTags__selected a.ReactTags__remove {
  background: #d1e5f1;
  color: #527893;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  input.ReactTags__tagInputField::placeholder {
    font-size: 10px;
    padding: 5px 0;
  }
  div.ReactTags__tagInput input.ReactTags__tagInputField,
  div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    font-size: 10px !important;
  }

  .selected-card {
    .card {
      min-height: 100vh;
    }
    .cardContainer {
      width: 100%;
      min-height: 100vh;
    }
  }
}
